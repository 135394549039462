<template>
  <c-box
    margin-top="24px"
  >
    <MealPlanProfileContainer
      :is-edited="isEdited"
      :data-kondisi-giziku="dataKondisiGiziku"
      :nutritional-intake="nutritionalIntake"
      :body-conditions="bodyConditions"
      :target-and-suggestions="targetAndSuggestions"
      :is-program-consultation-basic="isProgramConsultationBasic"
      :tabs="_tabs"
    />
  </c-box>
</template>

<script>
import { reqNutritionist_mealPlans_preview } from '@/requests/dietela-api/nutritionist/other'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import { CBox } from '@chakra-ui/vue'
import MealPlanProfileContainer from '@/components/meal-plan/profile/container.vue'
import { mapMutations } from 'vuex'
import mixinsTabsMealPlan from '@/mixins/mixins-tabs-meal-plan'
import { reqMenuMealPlan_TriggerForBE_1 } from '@/requests/dietela-api/other'

export default {
  components: {
    CBox,
    MealPlanProfileContainer,
  },
  mixins: [mixinsTabsMealPlan],
  props: {
    isEdited: {
      type: Boolean,
      default: true,
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataKondisiGiziku: null,
      nutritionalIntake: null,
      bodyConditions: [],
      targetAndSuggestions: null,
    }
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      async handler() {
        this.init()
        this.getMenuRecommendationWeekDays()
      },
    },
  },
  methods: {
    ...mapMutations({
      setMealPlanPreview: 'mealPlan/setMealPlanPreview',
      setMenuRecommendationWeeksDays: 'mealPlan/setMenuRecommendationWeeksDays',
    }),
    async init() {
      // Trigger for BE 1
      await reqMenuMealPlan_TriggerForBE_1(this.$store.getters.axios, {
        clientId: this.$route.params.clientId,
        programId: this.$route.params.programId,
        month: this.$route.params.month,
      })
      const res = await reqNutritionist_mealPlans_preview(this.$store.getters.axios,
        {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
        },
      )
      this.setMealPlanPreview(res?.data?.data)
      this.dataKondisiGiziku = res?.data?.data
      this.nutritionalIntake = res?.data?.data?.nutritionalIntake
      this.bodyConditions = res?.data?.data?.bodyConditions?.data ?? []
      this.targetAndSuggestions = res?.data?.data?.targetAndSuggestions
    },
    async getMenuRecommendationWeekDays() {
      const res = await reqNutriMealPlan.getMenuRecommendationWeekDays(this.$store.getters.axios,
        {
          clientId: this.$route.params?.clientId,
          programId: this.$route.params?.programId,
          month: this.$route.params.month,
        },
      )
      this.setMenuRecommendationWeeksDays(res?.data?.data)
    },
  },
}
</script>
